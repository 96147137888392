import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m10758 5383 l32 -5 -2 -5382 -3 -5381 -5379 -3 c-4297 -2 -5381 0
-5388 10 -8 15 -11 10730 -2 10753 5 13 633 14 5357 14 2944 0 5367 -3 5385
-6z"/>
<path d="M5279 7066 c-217 -63 -355 -274 -320 -490 27 -170 139 -298 311 -356
55 -19 81 -21 164 -18 69 4 93 2 80 -5 -28 -15 -30 -17 -14 -27 12 -8 12 -10
-3 -16 -22 -8 -50 3 -42 16 3 5 1 10 -4 10 -6 0 -11 -4 -11 -9 0 -14 -97 -21
-132 -10 -108 34 -189 80 -249 140 -31 31 -60 55 -63 52 -3 -4 -6 -23 -6 -43
0 -48 -7 -53 -40 -28 -56 41 -60 30 -60 -186 0 -107 -4 -197 -9 -200 -11 -7
-292 -39 -297 -34 -3 3 23 98 127 463 17 61 37 130 45 155 69 237 154 552 154
569 l0 23 -337 -4 c-248 -3 -346 -7 -368 -17 -16 -7 -39 -14 -50 -16 -98 -16
-193 -86 -241 -177 -21 -39 -69 -217 -109 -398 -9 -41 -29 -129 -46 -195 -16
-66 -38 -158 -48 -205 -11 -47 -33 -139 -49 -205 -17 -66 -40 -164 -53 -217
-12 -54 -25 -102 -28 -107 -10 -15 -283 -193 -288 -187 -6 5 -69 265 -124 501
-16 72 -40 173 -54 225 -27 109 -88 357 -140 575 -53 221 -88 286 -190 350
-108 68 -113 69 -414 73 -186 2 -277 0 -284 -7 -6 -6 -25 -11 -41 -11 -57 0
-58 -4 -54 -161 l5 -144 92 -320 c51 -176 104 -360 118 -410 13 -49 28 -103
32 -120 5 -16 19 -66 31 -110 12 -44 31 -109 41 -145 10 -36 34 -123 55 -195
92 -323 105 -372 110 -395 3 -14 10 -34 14 -45 5 -11 25 -81 44 -155 19 -74
47 -175 62 -224 l26 -88 -27 -72 c-56 -147 -97 -274 -115 -356 -23 -106 -55
-392 -47 -414 4 -9 17 -16 29 -16 19 0 26 10 41 58 25 78 139 304 187 372 22
30 40 52 40 48 0 -8 43 -162 81 -290 18 -63 22 -68 56 -77 20 -6 40 -8 44 -5
4 2 13 -5 19 -16 11 -20 19 -20 531 -20 l519 0 10 23 c5 12 35 112 66 222 30
110 89 317 129 460 41 143 90 319 110 390 20 72 48 171 62 220 14 50 27 99 30
111 4 16 17 23 66 32 116 22 312 43 372 40 l60 -3 2 -375 c4 -730 7 -797 33
-863 41 -104 115 -161 280 -218 l95 -33 275 -4 c299 -4 350 1 434 46 26 15 63
44 82 65 l33 39 -2 -40 c-2 -51 16 -72 60 -72 27 0 36 -5 40 -20 5 -20 12 -20
422 -18 l416 3 5 510 5 510 270 6 c213 5 295 11 388 27 310 57 530 154 708
314 110 99 211 273 251 433 21 83 23 111 23 375 0 283 0 286 -29 386 l-28 101
49 89 c126 228 213 415 213 458 0 29 -4 34 -27 39 -25 5 -40 -7 -138 -107
-126 -129 -233 -226 -250 -226 -6 0 -31 21 -55 46 -141 145 -416 273 -700 325
-121 22 -149 23 -818 29 -548 4 -696 2 -704 -7 -6 -7 -25 -13 -42 -13 -18 0
-37 -5 -44 -12 -9 -9 -12 -155 -12 -594 0 -484 -2 -583 -14 -593 -13 -11 -158
-8 -243 5 l-33 5 0 239 c0 132 -3 240 -7 240 -3 0 -18 -12 -32 -28 -32 -34
-96 -82 -110 -82 -7 0 -7 8 -1 24 5 13 6 26 4 29 -3 3 16 32 42 64 122 150
145 324 65 488 -48 96 -100 149 -196 196 -77 38 -91 42 -177 45 -58 2 -110 -2
-139 -10z m232 -66 c119 -38 225 -145 250 -256 19 -84 8 -209 -26 -274 -93
-182 -332 -259 -514 -165 -66 34 -139 109 -170 174 -23 49 -26 69 -26 161 0
99 2 109 33 172 36 74 87 125 167 166 92 47 183 54 286 22z m2064 -598 c62
-28 88 -53 131 -119 41 -62 42 -85 8 -107 -27 -18 -270 -115 -369 -148 -69
-22 -266 -78 -277 -78 -5 0 -8 108 -8 241 l0 241 233 -4 c216 -3 235 -5 282
-26z m-1995 -221 c0 -10 -24 -21 -46 -21 -20 0 -17 18 4 23 29 8 42 7 42 -2z
m529 -995 c7 -4 10 -141 11 -401 l0 -395 -29 0 c-60 0 -61 -2 -61 -124 0 -83
-4 -117 -15 -132 -13 -17 -14 -9 -15 78 0 109 -10 148 -39 148 -26 0 -31 -18
-31 -110 0 -126 -42 -189 -84 -128 -14 20 -16 89 -16 558 l0 536 134 -12 c74
-7 139 -15 145 -18z"/>
<path d="M5287 6885 c-95 -26 -207 -96 -207 -129 0 -16 21 -36 38 -36 7 0 34
14 59 31 78 54 145 72 253 67 98 -4 155 -24 231 -80 31 -23 57 -21 73 5 9 16
5 24 -26 51 -104 91 -281 129 -421 91z"/>
<path d="M5335 6758 c-63 -22 -126 -52 -141 -69 -31 -34 7 -88 44 -61 33 24
106 54 143 59 49 7 131 -13 175 -43 41 -28 57 -30 74 -9 31 37 -35 90 -150
120 -62 17 -102 17 -145 3z"/>
<path d="M5346 6615 c-50 -19 -66 -33 -66 -56 0 -29 32 -40 66 -23 21 11 42
14 74 9 25 -4 61 -8 80 -8 31 -2 35 1 35 23 0 49 -115 83 -189 55z"/>
<path d="M5383 6480 c-33 -14 -45 -44 -31 -77 16 -35 26 -43 59 -43 69 0 81
106 14 123 -11 3 -30 2 -42 -3z"/>
<path d="M4140 3720 c-19 -19 -20 -33 -20 -200 0 -110 4 -180 10 -180 6 0 11
68 12 170 2 103 7 171 13 173 6 1 12 -95 15 -265 6 -310 2 -299 100 -306 44
-3 66 0 85 12 24 16 25 21 25 111 0 117 -18 127 -22 13 -2 -66 -6 -83 -18 -83
-13 0 -15 38 -18 267 l-2 267 -27 20 c-36 29 -125 29 -153 1z"/>
<path d="M2937 3703 c-12 -12 -7 -58 9 -81 13 -18 24 -22 74 -22 l60 0 2 -242
3 -243 70 0 70 0 3 243 2 242 75 0 75 0 0 39 c0 63 -7 66 -235 69 -111 1 -205
-1 -208 -5z"/>
<path d="M3700 3543 c-8 -3 -29 -19 -47 -35 -31 -30 -31 -30 -42 -9 -13 23
-40 28 -59 9 -14 -14 -17 -168 -2 -168 5 0 10 35 12 78 2 60 6 77 18 77 12 0
16 -25 20 -142 6 -157 13 -178 81 -218 29 -17 52 -20 157 -20 98 0 125 3 132
15 13 20 13 200 0 200 -6 0 -10 -26 -10 -58 l0 -58 -97 4 c-54 2 -102 7 -106
11 -4 4 -9 21 -10 37 l-2 29 88 3 c59 2 87 7 88 15 1 7 0 41 0 76 -1 53 -6 70
-27 99 -15 19 -42 40 -60 48 -33 14 -105 18 -134 7z m104 -109 c9 -8 16 -27
16 -40 0 -23 -3 -25 -37 -22 -36 3 -38 5 -35 33 5 44 29 56 56 29z"/>
<path d="M4675 3538 c-11 -6 -32 -22 -47 -35 l-26 -25 -11 21 c-13 23 -41 27
-59 9 -14 -14 -17 -168 -2 -168 5 0 10 35 12 78 2 60 6 77 18 77 12 0 16 -24
20 -140 5 -153 10 -169 66 -212 34 -25 44 -27 155 -31 157 -6 159 -4 159 125
0 101 -17 129 -22 36 l-3 -58 -90 0 c-98 0 -129 14 -123 58 3 20 9 22 91 25
l87 3 0 66 c0 85 -26 141 -75 166 -40 19 -119 22 -150 5z m109 -104 c9 -8 16
-27 16 -40 0 -23 -3 -25 -37 -22 -30 2 -39 8 -41 24 -3 21 21 54 38 54 5 0 16
-7 24 -16z"/>
<path d="M5243 3531 c-17 -11 -36 -28 -42 -38 -9 -17 -11 -17 -26 5 -20 27
-51 29 -65 3 -13 -25 -13 -161 0 -161 5 0 10 35 12 78 2 60 6 77 18 77 12 0
16 -25 20 -140 3 -83 11 -149 18 -162 7 -12 29 -35 49 -50 35 -27 43 -28 162
-32 111 -3 126 -1 138 15 16 22 19 204 3 204 -5 0 -10 -25 -10 -55 0 -37 -4
-55 -12 -56 -60 -3 -178 0 -187 5 -7 5 -11 46 -11 110 0 98 1 104 22 109 33 9
48 -10 48 -59 l0 -44 39 0 c21 0 44 6 50 13 19 24 23 70 9 112 -10 32 -22 45
-58 63 -56 28 -133 30 -177 3z"/>
<path d="M5835 3533 c-16 -9 -40 -28 -52 -42 -12 -14 -24 -23 -25 -21 -2 3 -8
14 -14 25 -15 26 -38 30 -58 10 -18 -19 -23 -149 -6 -160 6 -4 10 18 10 59 0
72 6 96 25 96 9 0 14 -33 17 -127 3 -71 11 -143 18 -161 7 -19 31 -45 58 -64
41 -28 55 -32 114 -32 67 0 69 1 113 44 40 39 45 41 45 22 0 -15 8 -24 25 -28
43 -11 55 10 55 97 0 105 -18 102 -22 -3 -2 -66 -6 -83 -18 -83 -12 0 -16 24
-20 135 -4 118 -8 140 -28 172 -43 69 -164 100 -237 61z m113 -202 c-3 -103
-4 -106 -27 -109 -34 -5 -41 12 -41 115 0 98 9 117 52 105 17 -4 18 -14 16
-111z"/>
<path d="M6312 3538 c-15 -15 -17 -198 -2 -198 6 0 10 34 10 81 0 71 2 80 18
77 15 -3 18 -23 22 -193 l5 -190 67 -3 66 -3 4 161 c2 129 6 163 18 170 21 13
26 12 44 -6 13 -12 16 -41 16 -147 1 -159 5 -168 85 -175 l55 -4 0 143 c0 163
4 185 31 192 41 11 49 -16 49 -156 1 -169 3 -172 106 -172 56 0 77 4 84 15 13
20 13 207 0 194 -5 -5 -10 -43 -12 -84 -5 -133 -23 -84 -28 80 -3 96 -10 163
-18 177 -33 59 -115 72 -161 25 -15 -15 -31 -34 -34 -42 -6 -13 -8 -13 -14 0
-16 38 -62 70 -98 70 -43 0 -89 -24 -103 -54 -10 -21 -10 -21 -17 -1 -18 47
-35 55 -110 55 -42 0 -76 -5 -83 -12z"/>
<path d="M7066 3275 c-37 -35 -37 -105 0 -134 29 -23 83 -28 115 -10 12 6 26
24 33 41 39 92 -74 171 -148 103z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
